import React, { useState } from "react";
import logo from "src/assets/images/CleanAce-logo.png";
import { ErrorMessage, Formik } from "formik";
import * as yup from "yup";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { useMutation } from "@tanstack/react-query";
import { Modal } from "src/components";
import { signin } from "src/api";
import ForgotPassword from "./ForgotPassword";
import { useProtectedRoutesContext } from "src/context/ProtectedRoutes";
import { renderErrorMessage } from "src/assets/functions";
import { AlertIcon, RightIcon } from "src/assets/svg";

const Login = () => {
	const { setUser, setToken } = useProtectedRoutesContext();

	const [isCreateChapterModalOpen, setIsCreateChapterModalOpen] = useState(false);
	const [visible, setVisible] = useState(false);

	const CancelModal = () => {
		setIsCreateChapterModalOpen(false);
	};
	const signInSchema = yup.object().shape({
		email: yup.string().email("Please provide a valid email").required("Please provide a valid email"),
		password: yup
			.string()
			.required("Please provide password")
			.min(8, "Password must be 8 characters long")
			.matches(/[0-9]/, "Password requires a number")
			.matches(/[a-z]/, "Password requires a lowercase letter")
			.matches(/[A-Z]/, "Password requires an uppercase letter")
			.matches(/[^\w]/, "Password requires a symbol"),
	});
	const initialValues = {
		email: "demoaccount@cleanance.com.ng",
		password: "DemoPassword1@",
	};

	const { mutate, isLoading } = useMutation(signin, {
		onSuccess: (data) => {
			setUser(data?.user);
			setToken(data?.token);
		},
		onError: (error) => {
			renderErrorMessage(error.message);
		},
	});
	return (
		<>
			<div className="w-[550px]">
				<div className="flex items-center justify-center my-12">
					<img src={logo} className="w-[200px]" alt="Logo" />
				</div>
				<h1 className="text-center text-[#0132ad] text-[4.6rem] font-bold mb-[15px]">Welcome Back!</h1>
				<div className="px-[20px] py-4 border bg-[#F3F7FF] border-[#0000A7] rounded-lg flex items-start gap-4">
					<AlertIcon className="block mt-2" />
					<div>
						<p className="text-[#0000A7] text-[1.8rem] font-medium mb-2">Please login with the following credentials.</p>
						<p className="text-[1.4rem] text-[#374B6E]">Email: demoaccount@cleanance.com.ng</p>
						<p className="text-[1.4rem] text-[#374B6E]">Password: DemoPassword1@</p>
						<a
							href="https://demo.laundrychains.cleanace.com.ng/cleanace-demo-app.apk"
							className="flex items-center gap-2 text-[#0000A7] font-semibold mt-4 text-[1.4rem]"
							download={true}
						>
							Download Mobile App <RightIcon />
						</a>
					</div>
				</div>
				<div className=" flex justify-center align-center my-[50px]">
					<Formik initialValues={initialValues} validationSchema={signInSchema} onSubmit={(values) => mutate(values)}>
						{({ values, handleChange, handleBlur, handleSubmit }) => (
							<form className="w-[90%] max-w-[500px] " onSubmit={handleSubmit}>
								<div className="form-group mb-[20px]">
									<label className="font-[500px] text-[1.4rem] text-black">Email Address</label>
									<input
										type="text"
										className="border control-form color-darkGrey"
										name="email"
										placeholder="Enter your Email Address"
										onChange={handleChange}
										value={values.email}
										onBlur={handleBlur}
									/>
									<ErrorMessage name="email" component="div" className="text-red-500  text-[1.4rem]" />
								</div>
								<div className="form-group mb-[20px]">
									<label className="font-[500px] text-[1.4rem] text-black">Password</label>
									<div className="has-pwd-icon">
										<input
											className="border control-form color-darkGrey"
											placeholder="Enter your password"
											type={visible ? "text" : "password"}
											name="password"
											onChange={handleChange}
											value={values.password}
											onBlur={handleBlur}
										/>
										{visible ? (
											<BsEye className="view-pwd" size={20} onClick={() => setVisible((prevState) => !prevState)} />
										) : (
											<BsEyeSlash className="view-pwd" size={20} onClick={() => setVisible((prevState) => !prevState)} />
										)}
									</div>
									<ErrorMessage name="password" component="div" className="text-red-500 text-[1.4rem]" />
								</div>
								<div className="flex justify-between form-group">
									<div className="form-check">
										<input className="form-check-input" type="checkbox" id="gridCheck" />
										<label className="ml-2 form-check-label text-[1.4rem]" htmlFor="gridCheck">
											Remember Me
										</label>
									</div>
									<button type="button" onClick={() => setIsCreateChapterModalOpen(true)}>
										Forgotten Password?
									</button>
								</div>
								<button
									type="submit"
									className="btn submit-signup w-full bg-[#0132ad] text-white p-[15px] text-[1.4rem]  mt-[4rem]"
									disabled={isLoading}
								>
									Login
								</button>
							</form>
						)}
					</Formik>
				</div>
			</div>
			<Modal
				isOpen={isCreateChapterModalOpen}
				setIsOpen={setIsCreateChapterModalOpen}
				size="sm"
				title="Forgot Password?"
				subtitle="Enter your email to receive your verification code."
			>
				<ForgotPassword CancelModal={CancelModal} />
			</Modal>
		</>
	);
};

export default Login;
